import { Box, Image, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Tick from "./Tick.png";
// import ReactMarkdown from 'react-markdown';
// import ChakraUIRenderer from 'chakra-ui-markdown-renderer';

function ProductCard({ title, imageURL, price, SEOTitle, ticks, sellixID, description, stock }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    // const newTheme = {
    //     p: (props) => <Text color="#dfdfdf" fontSize={"0.9em"} mb={2} {...props} />,
    //     h1: (props) => <Text color="#dfdfdf" as="h1" fontSize="1xl" fontWeight="bold" mb={4} {...props} />,
    //     h2: (props) => <Text color="#dfdfdf" as="h2" fontSize="l" fontWeight="bold" mb={4} {...props} />,
    //     h3: (props) => <Text color="#dfdfdf" as="h3" fontSize="lg" fontWeight="bold" mb={4} {...props} />,
    //     a: (props) => <Link color="#7831B2" {...props} />,
    //     ul: (props) => <List color="#dfdfdf" fontSize={"0.9em"} styleType="disc" pl={4} mb={2} {...props} />,
    //     ol: (props) => <List color="#dfdfdf" fontSize={"0.9em"} styleType="decimal" pl={4} mb={2} {...props} />,
    //     li: (props) => <ListItem color="#dfdfdf" mb={1} {...props} />,
    //     em: (props) => <Text color="#dfdfdf" as="em" fontStyle="italic" {...props} />,
    //     strong: (props) => <Text color="#dfdfdf" fontSize={"0.5em"} as="strong" fontWeight="bold" {...props} />,
    // };
    
    ticks = ["Instant Delivery", "Revoke Warranty", "Impossible for Autoclaim", "Fresh Links"]

    return (
        <>
            <Box
                borderRadius={6}
                id={`product-${SEOTitle}`}
                width={"100%"}
                maxWidth={300}
                height={"auto"}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                outline={"2px solid #000"}
            >
                <Box>
                    <Image borderTopRadius={6} width="100%" src={imageURL}></Image>

                    <Box paddingX={3} marginTop={2}>
                        <Text
                            textTransform={"uppercase"}
                            id="cardTitle"
                            color="#000"
                            noOfLines={1} // Limit the title to 2 lines
                            minHeight="40px" // Ensure a minimum height for the title box
                        >
                            {title}
                        </Text>
                        <Text
                            id="cardP"
                            color="#727272"
                            fontSize={"0.9em"}
                            noOfLines={2}
                        >
                            We guarantee instant delivery, warranties and much more.
                        </Text>

                        <Box
                            marginTop={3}
                            marginBottom={3}
                            width={"100%"}
                            height={0.1}
                            backgroundColor={"rgba(79, 79, 122, 0.7)"}
                        />

                        <Box
                            maxHeight="200px" // Adjust to suit the visual requirements
                            overflow="hidden"
                            sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: 7,
                                WebkitBoxOrient: 'vertical',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}
                            marginBottom={3}
                        >
                            {ticks.map((tick, index) => (
                                <Box display={"flex"}>
                                    <Image marginRight={2} marginBottom={3} marginTop={0} width={5} height={4} style={{ aspectRatio: 63.8/50 }} src={Tick}></Image>
                                    <p id="cardTick">{tick}</p>
                                </Box>
                            ))}
                            {/* <ReactMarkdown components={ChakraUIRenderer(newTheme)} children={description} /> */}
                        </Box>
                            
                        <Text id="cardTick" fontWeight={600}>STOCK: {stock}</Text>
                    </Box>
                </Box>

                <Box display={"flex"} padding={3} marginTop={-2} paddingBottom={1}>
                    <Button
                        data-sellix-product={sellixID}
                        type="submit"
                        alt="Buy Now with sellix.io"
                        onClick={!sellixID ? onOpen : console.log()}
                        backgroundColor={"#FFFFFF"}
                        outline={"1px solid black"}
                        borderRadius={3}
                        color={"#000"}
                        _hover={{ backgroundColor: "#C785EF" }}
                        _active={{ backgroundColor: "#d5a3f3" }}
                        transition={"0.25s"}
                        style={{ cursor: "pointer" }}
                        zIndex={1}
                    >
                        Buy Now
                    </Button>
                    <Text
                        marginLeft={"auto"}
                        id="cardTitle"
                        lineHeight={"normal"}
                        color="#000"
                        fontSize={30}
                        marginTop={1}
                    ><span id="euro">€</span>{price}</Text>
                </Box>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Coming Soon!</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            OUT OF STOCK! Join our{" "}
                            <Text
                                display={"inline"}
                                _hover={{ textDecoration: "underline" }}
                                color="#3663FF"
                            >
                                <a href="https://discord.gg/vntvYWEXnZ">Discord</a>
                            </Text>{" "}
                            to know as soon as we next get stock!
                            <br />
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={onClose}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </>
    );
}

export default ProductCard;
