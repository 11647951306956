import { Box, Text, Flex } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons"
import React from "react";

function Review({ timestamp, comment, score }){
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString('en-US'); 

    return (
        <Box id="review" borderRadius={6} width={450} height={"auto"} outline={"2px solid black"} padding={5}>
            <Flex gap={3} alignItems={"center"}>
                {/* <Image borderRadius={6} width={10} src={avatarURL}></Image> */}
                <Text id="cardTitle" fontSize={"20px !important"} color={"black"}>{formattedDate}</Text>

                <Box marginLeft={"auto"} paddingRight={2}>
                    {Array.from({ length: score }, (_, index) => (
                        <StarIcon key={index} marginRight={1} color={"black"} />
                    ))}

                    {/* <StarIcon marginRight={1} color={"yellow"}/>
                    <StarIcon marginRight={1} color={"yellow"}/>
                    <StarIcon marginRight={1} color={"yellow"}/>
                    <StarIcon marginRight={1} color={"yellow"}/>
                    <StarIcon color={"yellow"}/> */}
                </Box>
            </Flex>

            <Text id="cardP" fontSize={"15px !important"}>{comment}</Text>
        </Box>
    )
}

export default Review