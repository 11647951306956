// import React, { useEffect, Component } from 'react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ChakraProvider, Box, Image, Flex, Text, Stack, Button } from '@chakra-ui/react';
import ProductCard from './ProductCard';
import { Helmet } from "react-helmet";
// import backgroundImage from "./background.jpg";
import Discord from "./Discord.png";
import Logo from "./Logo.png";
// import Nitro from "./Nitro.png";
// import Boosts from "./Boosts.png";
// import Disney from "./disney.png";
// import Spotify from "./spotify.png";
import Banner from "./banner.png";
import Review from './Review'; 
// import { Crisp } from "crisp-sdk-web";
// import ScriptTag from 'react-script-tag';

// import { ColorModeSwitcher } from './ColorModeSwitcher';
// import { Logo } from './Logo';

// class App extends Component {
  // componentDidMount() {
  //   Crisp.configure("4c68df26-b844-45f6-8de9-b4c1e7b9de8e");
  // }

function App(){
    function scrollTo(element){
    const section = document.querySelector(element);

    if(section){
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  };

  const [productsList, setProducts] = useState([]);
  const [hasLogged, setHasLogged] = useState(false);

  const [feedbackList, setFeedback] = useState([]);
  const [hasLoggedFeedback, setHasLoggedFeedback] = useState(false);


  const AUTHORIZATION = process.env.REACT_APP_AUTHORIZATION;
  const MERCHANT = process.env.REACT_APP_MERCHANT;
  const discordInviteLink = process.env.REACT_APP_DISCORD_INVITE;
  const DOMAIN = process.env.REACT_APP_DOMAIN;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://dev.sellix.io/v1/products', {
          headers: {
            'Authorization': AUTHORIZATION,
            'X-Sellix-Merchant': MERCHANT,
          },
        });
        setProducts(response.data.data.products);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
  
    const fetchFeedback = async () => {
      try {
        const response = await axios.get('https://dev.sellix.io/v1/feedback?page=1', {
          headers: {
            'Authorization': AUTHORIZATION,
            'X-Sellix-Merchant': MERCHANT,
          },
        });
        setFeedback(response.data.data.feedback);
      } catch (error) {
        console.error('Error fetching feedback:', error);
      }
    };
  
    fetchProducts();
    fetchFeedback();
  }, [AUTHORIZATION, MERCHANT]);

  useEffect(() => { 
    if (!hasLogged && productsList.length > 0) {
      console.log('Products List Updated:', productsList);
      setHasLogged(true);
    }
  }, [productsList, hasLogged]);

  useEffect(() => {
    if (!hasLoggedFeedback && feedbackList.length > 0) {
      console.log('Feedback List Updated:', feedbackList);
      setHasLoggedFeedback(true);
    }
  }, [feedbackList, hasLoggedFeedback]);


  // useEffect(() => {
  //   const bg = document.getElementById("bg-img");

  //   // bg.style.setProperty("background-color", "#FAE6B3")
  // }, []);

  // const discordInviteLink = "https://discord.gg/MARMELADA";

  return(
    <ChakraProvider>
      <div>
        <Helmet>
          <title>Cheap Discord Nitro Gifts - MARMELADA</title>
          <meta charset="UTF-8"/>
          <link rel="canonical" href={DOMAIN}/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta name="description" content="Get the best deals on Discord Nitro gifts! Join our community for exclusive offers and discounts."/>

          <meta property="og:title" content="Cheap Discord Nitro Gifts - MARMELADA"/>
          <meta name="twitter:title" content="Cheap Discord Nitro Gifts - MARMELADA"/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={DOMAIN}/>
          <meta property="og:image" content="https://i.postimg.cc/6qtBppWj/Nitro.png"/>
          <meta property="og:description" content="Get the best deals on Discord Nitro gifts! Join our community for exclusive offers and discounts."/>
          <meta name="twitter:description" content="Get the best deals on Discord Nitro gifts! Join our community for exclusive offers and discounts."/>
          <meta name="twitter:image" content="https://i.postimg.cc/6qtBppWj/Nitro.png"/>
          <meta name="twitter:site" content="@discord"/>
          <meta property="og:site_name" content="MARMELADA"/>
          <meta name="robots" content="index, follow"/>

          <meta name="twitter:card" content="summary_large_image"/>

          <meta name="color-scheme" content="dark"/>
          <meta name="darkreader-lock"/>

          <script src="https://cdn.sellix.io/static/js/embed.js"></script>
          <link href="https://cdn.sellix.io/static/css/embed.css" rel="stylesheet"/>
        </Helmet>

        <Box height="100vh" width="100vw" backgroundColor={"#fff"} overflowX={"hidden"} style={{ overflowX: "hidden" }}>
          <Box height={"auto"} position={"absolute"} className='home'>

            

            {/* Header Navigation */}
            <Flex
              className='header'
              width="80%"
              marginTop={10}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
              // display={"none"}
            >
              <Stack direction={"row"} spacing={10} alignItems={'center'} color="#000">
                <Image id="logo" alt="logo" width={"8%"} maxWidth={"50px"} borderRadius={10} src={Logo} _hover={{ cursor: "pointer", transform: "scale(1.05)" }} transition={"0.1s"}></Image>
                <Text fontWeight={400} id="top-nav" display={"block"} _hover={{ color: "#c4c4c4", cursor: "pointer" }} transition={"0.1s"} borderBottom={"3px solid #000"}>HOME</Text>
                <Text fontWeight={400} id="top-nav" display={"block"} onClick={() => scrollTo("#productPage")} _hover={{ color: "#c4c4c4", cursor: "pointer" }} transition={"0.1s"}>PRODUCTS</Text>
                <Text fontWeight={400} id="top-nav" display={"block"} onClick={() => scrollTo("#contactPage")} _hover={{ color: "#c4c4c4", cursor: "pointer" }} transition={"0.1s"}>STATUS</Text>
                <Text fontWeight={400} id="top-nav" display={"block"} onClick={() => scrollTo("#contactPage")} _hover={{ color: "#c4c4c4", cursor: "pointer" }} transition={"0.1s"}>CONTACT</Text>
                <Text fontWeight={400} id="top-nav" display={"block"} onClick={() => scrollTo("#aboutusPage")} _hover={{ color: "#c4c4c4", cursor: "pointer" }} transition={"0.1s"}>ABOUT US</Text>
              </Stack>

              <Stack
                marginLeft={"auto"}
                direction={"row"}
                spacing={5}
                alignItems={'center'}
                color="white"
              >
                  <a href={discordInviteLink} target="_blank" rel="noopener noreferrer">
                    <Image alt="loading" width="40px" marginRight={5} filter={"brightness(500%)"} src={Discord}></Image>
                  </a>
                  <Button
                    onClick={() => scrollTo("#productPage")}
                    backgroundColor={"#000"}
                    color={"#fff"}
                    _hover={{ backgroundColor: "#c4c4c4" }}
                    _active={{ transform: "translate(4px, 4px)", boxShadow: "0px 0px #c4c4c4" }}
                    boxShadow="4px 4px #000"
                    transition={"0.2s"}
                    paddingLeft={"30px"}
                    paddingRight={"30px"}
                    height="40px"
                  >
                    Buy Now
                  </Button>
              </Stack>
            </Flex>

            {/* Main Content */}
            <Box
              display={"flex"}
              width={"80%"}
              marginTop={"10%"}
              position={'absolute'}
              left="50%"
              transform={"translateX(-50%)"}
              id="main-container"
            >
              <Box id="title-container" marginRight={20} flex={1} maxWidth={"60%"}>
                <Text id="redhatdisplay" color="#000" top={-5} position={'absolute'}>Discord Nitro</Text>
                <Text marginTop={1} width={"90%"} id="title" color="#000000" fontSize={{ base: "5em", md: "10em" }} whiteSpace={"nowrap"} textShadow={"0px 0px 8px #000"}>MARMELADA</Text>
                <Box backgroundColor={"rgba(217, 217, 217, 0.35)"} outline={"1px solid #999999"} marginTop={5} padding={5} paddingTop={1} borderRadius={20}>
                  <Text id="paragraph" color={"#000 !important"} background={"none"} fontSize={{ base: "0.75em", md: "1.25em" }}>Looking for the best prices? Check out MARMELADA, the cheapest store you need! We offer unbeatable deals! Discord Nitro Monthly for less than €2 and Discord Nitro Yearly for less than €10!</Text>
                </Box>
                <Button
                  onClick={() => scrollTo("#productPage")}
                  marginTop={30}
                  marginRight={5}
                  fontSize={18}
                  borderRadius={3}
                  textTransform={'uppercase'}
                  backgroundColor={"#000"}
                  paddingLeft={5}
                  paddingRight={5}
                  paddingTop={6}
                  paddingBottom={6}
                  color={"#fff"}
                  _hover={{ backgroundColor: "#202020" }}
                  _active={{ backgroundColor: "#3a3a3a" }}
                  boxShadow="0px 0px 38px 2px rgba(0, 0, 0, 0.25)"
                  transition={"0.25s"}
                >
                  Products
                </Button>
                <Button
                  onClick={() => scrollTo("#aboutusPage")}
                  marginTop={30}
                  fontSize={18}
                  borderRadius={3}
                  textTransform={'uppercase'}
                  outline={'2px solid #000'}
                  outlineOffset={-2}
                  background={"rgba(255, 255, 255, 0.2)"}
                  color={"#000"}
                  paddingLeft={5}
                  paddingRight={5}
                  paddingTop={6}
                  paddingBottom={6}
                  _hover={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  _active={{ backgroundColor: "#818def" }}
                  transition={"0.25s"}
                >
                  About Us
                </Button>
              </Box>

              {/* Additional content or image
              <Image
                position={"relative"}
                right={0}
                top={20}
                opacity={0.75}
                src="https://i.imgur.com/lBSZlGZ.png"
              /> */}
            </Box>
            
            {/* Products Section */}
            <Box
              id="productPage"
              width={"80%"}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
              marginTop={"90vh"}
            >
              <Box id="title-container-sections" maxWidth={"60%"} marginBottom={10}>
                <Text marginTop={2} id="phantom" color="#000" fontSize={"4em"}>Products</Text>
              </Box>

              <Flex flexDirection={'row'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'} marginBottom={15} gap={5}>
                {/* {productData.map((product, index) => (
                  <div key={index}>
                    <ProductCard SEOTitle={product.SEOTitle} title={product.title} imageURL={product.imageURL} price={product.price} ticks={product.ticks} sellixID={product.sellixID} />
                  </div>
                ))} */}
                {productsList.map((product, index) => (
                  <div key={index}>
                    <ProductCard stock={product.stock} description={product.description} SEOTitle={product.slug} title={product.title} imageURL={`https://imagedelivery.net/95QNzrEeP7RU5l5WdbyrKw/${product.cloudflare_image_id}/shopitem`} price={product.price_display} sellixID={product.uniqid} />
                  </div>
                ))}
              </Flex>
            </Box>

            {/* Other content such as Reviews, About Us, Contact Us, etc. */}
            <Box
              id="reviews"
              marginTop={"60px"}
              width={"80%"}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
            >
              <Box maxWidth={"100%"} id="title-container-sections" marginBottom={10}>
                <Text marginTop={2} id="phantom" color="#000" fontSize={"4em"}>Top Reviews</Text>
              </Box>

              <Flex flexWrap='wrap' justifyContent='center' alignItems='center' gap={10}>
                {/* {feedbackList.slice(0, 6).map((review, index) => (
                  <div key={review.id}>
                    <Review comment={review.message} timestamp={review.created_at} score={review.score} />
                  </div>
                ))} */}

                {feedbackList.map((review, index) => (
                  <div key={review.id}>
                    <Review comment={review.message} timestamp={review.created_at} score={review.score} />
                  </div>
                ))}
              </Flex>
            </Box>


            <Box
              id="aboutusPage"
              marginTop={"60px"}
              width={"80%"}
              position={'relative'}
              left="50%"
              transform={"translateX(-50%)"}
              marginBottom={20}
            >
              <Box maxWidth={"100%"} id="title-container-sections" marginBottom={10}>
                <Text marginTop={2} id="phantom" color="#000" fontSize={"4em"}>About Us</Text>
              </Box>

              <Text color={"#333333"} fontSize={{ base: "0.9em", md: "1.25em" }}>Welcome to MARMELADA – your one-stop destination for premium services at unbeatable prices! At MARMELADA, we're dedicated to providing you with Discord Nitro Monthly and Yearly, all at jaw-droppingly low rates. How do we do it? Well, we've mastered the art of leveraging various methods such as region changes, specialized tools, and other ingenious strategies to bring you these services without breaking a sweat. Our mission is simple: to make the best services accessible to everyone, regardless of budget constraints. With MARMELADA, you can enjoy the perks of Discord Nitro without burning a hole in your pocket. So why wait?</Text>
            </Box>

            
            <Box
              width="100vw"
              // height="600px"
              backgroundImage={Banner}
              backgroundSize="cover"
              position="relative"
              id="contactPage"
              // padding="150px"
              paddingLeft={150}
              paddingRight={150}
              paddingTop={150}
              paddingBottom={150}
              zIndex={0}
            >
              <Box
                width="100%"
                height="100%"
                justifyContent={'center'}
                alignItems={'center'}
                backgroundColor="rgba(255, 255, 255, 0.02)"
                zIndex={1}
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
              />
              
              <Stack padding={5} paddingBottom={7} justifyContent={'center'} alignItems={'center'} direction={"column"} spacing={5} position={'relative'} zIndex={1} outline={"2px solid black"} backgroundColor={"rgba(255, 255, 255, 0.2)"} borderRadius={20}>
                <Text backgroundColor={"transparent"} color={"#000"} fontSize={"3em"} fontWeight={600}>Talk to us now!</Text>
                <Text backgroundColor={"transparent"} color={"#000"} fontSize={{ base: "0.9em", md: "1.25em" }} width={"50%"} textAlign={"center"}>Join us in our Discord community, MARMELADA. Engage with others in our server, and unlock exclusive access to the best deals and
                offers on Discord Nitro, and know our stock before anyone else!</Text>
                
                <a backgroundColor={"transparent"} href={discordInviteLink} target="_blank" rel="noopener noreferrer">
                  <Button _hover={{ backgroundColor: "#5e82ff" }} backgroundColor={"transparent"} outline={"2px solid black"} borderRadius={20} width={"200px"} height={"75px"} marginTop={5}>
                    <Stack backgroundColor={"transparent"} direction={"row"} justifyContent={"center"} alignItems={"center"} height="100%" gap={2}>
                      <Image backgroundColor={"transparent"} alt="loading" width="50px" filter={"brightness(500%)"} src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"></Image>
                      <Text backgroundColor={"transparent"} color={"#fff"} fontSize={24}>Discord</Text>
                    </Stack>
                  </Button>
                </a>
              </Stack>
            </Box>
          </Box>
        </Box>
      </div>
    </ChakraProvider>
  );
}

export default App;
